import React from 'react';
import ProfileDetail from './ProfileDetail';
import InfomationSkill from './InfomationSkill';

export default function InfomationBox() {
  return (
    <div>
      <ProfileDetail />
      <InfomationSkill />
    </div>
  );
}
