import React from 'react';
import BlogBoxContent from './BlogBoxContent';

export default function BlogBox() {
  return (
    <div>
      <BlogBoxContent />
    </div>
  );
}
