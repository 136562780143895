import CoursesStats from '../component/CoursesStats';
import CoursesList from '../component/CoursesList';
export default function Courses() {
  return (
    <div>
      <CoursesStats />
      <CoursesList />
    </div>
  );
}
